/*
Copyright [2024] [Gerald Rocher]

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { findClosestIndex } from './utils/utils';
import ResilienceMetricsScoreCardsManager from './components/ResilienceMetricsScoreCardsManager';
import Map from './components/Map';
import ResilienceMetricsComputation from './components/ResilienceMetricsComputation';
import TimeSeries from './components/TimeSeries';
import GraphManager from './components/GraphManager';
import { fetchColumnData } from './components/TimeSeries';

function ResilienceMetricsAnalyser() {
  const chartRef = useRef(null);

  // États locaux
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [smoothingFactor, setSmoothingFactor] = useState(1.0);
  const [linePositions, setLinePositions] = useState([10, 20]);
  const [selectedLineIndex, setSelectedLineIndex] = useState(null);
  const [highlightedLineIndex, setHighlightedLineIndex] = useState(null);
  const [displayedChartsData, setDisplayedChartsData] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [circles, setCircles] = useState([]);
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [savedSelections, setSavedSelections] = useState([]);

  // Handlers for dates
  const handleDateChange = useCallback((type, e) => {
    const newDate = new Date(e.target.value).toISOString().split('T')[0];
    if (type === 'start') {
      setDateStart(newDate > dateEnd && dateEnd ? dateEnd : newDate);
    } else {
      setDateEnd(newDate < dateStart && dateStart ? dateStart : newDate);
    }
  }, [dateStart, dateEnd]);

  // Function to display/hide timeseries
  const toggleDataset = useCallback(async (column) => {
    const currentIndex = displayedChartsData.findIndex(item => item.label === column);

    if (currentIndex === -1) {
      await addNewColumnData(column);
    } else {
      removeColumnData(currentIndex, column);
    }
  }, [displayedChartsData]);

  // Show a timeseries
  const addNewColumnData = async (column) => {
    const data = await fetchColumnData(column, dateStart, dateEnd, smoothingFactor);
    setDisplayedChartsData(prev => [...prev, data]);
    setVisibleColumns(prev => [...prev, column]);

    if (data.ctx) {
      const coordinates = extractCoordinates(data.ctx);
      if (coordinates) {
        const [longitude, latitude] = coordinates;
        setMarkers(prevMarkers => [
          ...prevMarkers,
          { position: [latitude, longitude], label: column }
        ]);
      }
    }
  };

  // Hide a timeseries
  const removeColumnData = (currentIndex, column) => {
    setDisplayedChartsData(prev => prev.filter((_, index) => index !== currentIndex));
    setVisibleColumns(prev => prev.filter(visibleColumn => visibleColumn !== column));
    setMarkers(prevMarkers => prevMarkers.filter(marker => marker.label !== column));
  };

  // Extraction des coordonnées
  const extractCoordinates = (ctx) => {
    const coordinatesMatch = ctx.match(/\(([^)]+)\)/);
    return coordinatesMatch ? coordinatesMatch[1].split(',').map(Number) : null;
  };

  // Rechargement des données associées à une sélection
  const reloadSelection = useCallback(async (selection) => {
    const chart = chartRef.current;
    if (!chart) return;

    const data = await fetchColumnData(selection.chartName, dateStart, dateEnd, smoothingFactor);
    const existingData = displayedChartsData.find(item => item.label === selection.chartName);

    if (!existingData) {
      setDisplayedChartsData(prev => [...prev, data]);
      setVisibleColumns(prev => [...prev, selection.chartName]);
    }

    setSelectedColumns([data]);

    const [startIndex, endIndex] = [
      findClosestIndex(data.timestamps, selection.timestamps[0]),
      findClosestIndex(data.timestamps, selection.timestamps[1])
    ];

    if (startIndex !== -1 && endIndex !== -1) {
      setLinePositions([startIndex, endIndex]);
      setSelectedLineIndex(null);
      setHighlightedLineIndex(null);
    }
  }, [displayedChartsData, dateStart, dateEnd, smoothingFactor]);

  // Mise à jour des données du graphique
  useEffect(() => {
    const updateChartData = async () => {
      if (!selectedColumns) return;

      const datasets = await Promise.all(      
        displayedChartsData.map(data => fetchColumnData(data.label, dateStart, dateEnd, smoothingFactor))
      );

      const selectedLabels = new Set(selectedColumns.map(column => column.label));

      const chartDatasets = datasets.map((data, index) => {
        const isSelected = selectedLabels.has(data.label);
        return {
          label: data.label,
          data: data.smoothedValues,
          borderColor: isSelected ? 'rgba(244, 122, 96, 1)' : 'rgba(49, 104, 121, 1)',
          backgroundColor: isSelected ? 'rgba(244, 122, 96, 0.25)' : 'rgba(49, 104, 121, 0.25)',
          fill: true,
          tension: 0.1,
        };
      });

      setChartData({
        labels: datasets[0]?.timestamps,
        datasets: chartDatasets,
      });
    };

    updateChartData();
  }, [displayedChartsData, smoothingFactor, dateStart, dateEnd, selectedColumns]);

  return (
    <div className="ResilienceMetricsAnalyser">
      <h1>AWARE4BC Resilience Curves Analysis</h1>

      <TimeSeries
        visibleColumns={visibleColumns}
        toggleDataset={toggleDataset}
      />

      {displayedChartsData.length > 0 && (
        <>
          <div className="graph-config">
            <div className="smoothing-factor">
              <h4>Smoothing factor</h4>
              <input
                className="smoothing-factor-slider"
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={smoothingFactor}
                onChange={(e) => setSmoothingFactor(parseFloat(e.target.value))}
              />
              <h4>{smoothingFactor.toFixed(2)}</h4>
            </div>
            <div>
              <h4>Filter</h4>
            </div>
            <div>
              <input
                type="date"
                value={dateStart}
                onChange={(e) => handleDateChange('start', e)}
                placeholder="From"
              />
              <input
                type="date"
                value={dateEnd}
                onChange={(e) => handleDateChange('end', e)}
                placeholder="To"
              />
            </div>
          </div>

          <div className="graph-and-map-container">
            <GraphManager
              chartRef={chartRef}
              chartData={chartData}
              linePositions={linePositions}
              setLinePositions={setLinePositions}
              selectedLineIndex={selectedLineIndex}
              setSelectedLineIndex={setSelectedLineIndex}
              highlightedLineIndex={highlightedLineIndex}
              setHighlightedLineIndex={setHighlightedLineIndex}
              setCircles={setCircles}
              markers={markers}
              displayedChartsData={displayedChartsData}
              setSelectedColumns={setSelectedColumns}
            />
            <Map 
              circles={circles}
            />
          </div>

          {linePositions.length === 2 && (
            <div className="resilience-metrics-selector">
              <h3>Resilience metrics</h3>
              <ResilienceMetricsComputation
                linePositions={linePositions}
                selectedColumns={selectedColumns}
                chartData={chartData}
                savedSelections={savedSelections}
                setSavedSelections={setSavedSelections}
                dateStart={dateStart}
                dateEnd={dateEnd}
              />
            </div>
          )}
        </>
      )}

      <ResilienceMetricsScoreCardsManager
        savedSelections={savedSelections}
        setSavedSelections={setSavedSelections}
        reloadSelection={reloadSelection}
      />
    </div>
  );
}

export default ResilienceMetricsAnalyser;
