/*
Copyright [2024] [Gerald Rocher]

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
/**
 * Smooth a data array using an exponentially weighted moving average (EWMA)
 * 
 * @param {Array<number>} data - Array of numbers representing the data to smooth
 * @param {number} smoothingFactor - Smoothing factor between 0 and 1, where higher values give smoother results
 * @returns {Array<number>} Smoothed data array
 */

export const smoothData = (data, smoothingFactor = 0.6) => {
    if (!Array.isArray(data) || data.length === 0) return [];

    if (smoothingFactor < 0 || smoothingFactor > 1) {
        throw new Error('Smoothing factor should be between 0 and 1');
    }

    const smoothedData = [data[0]];  // Initialiser avec la première valeur brute

    // Appliquer le lissage exponentiel
    for (let i = 1; i < data.length; i++) {
        const previousSmoothedValue = smoothedData[i - 1];  // Prendre la valeur lissée précédente
        const currentValue = data[i];
        const smoothedValue = smoothingFactor * currentValue + (1 - smoothingFactor) * previousSmoothedValue;
        smoothedData.push(smoothedValue);
    }

    return smoothedData;
};


export function findClosestIndex(timestamps, targetTimestamp) {
  const targetTime = Date.parse(normalizeDate(targetTimestamp));

  let left = 0;
  let right = timestamps.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midTime = Date.parse(normalizeDate(timestamps[mid]));

    if (midTime === targetTime) {
      return mid; // Exact match trouvé
    }

    if (midTime < targetTime) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  const leftTime = left < timestamps.length ? Date.parse(normalizeDate(timestamps[left])) : Infinity;
  const rightTime = right >= 0 ? Date.parse(normalizeDate(timestamps[right])) : -Infinity;

  return Math.abs(leftTime - targetTime) < Math.abs(rightTime - targetTime) ? left : right;
};

export function normalizeDate(dateStr) {
  "DD/MM/YYYY, HH:mm:ss"
  const [datePart, timePart] = dateStr.split(', ');
  const [day, month, year] = datePart.split('/');
  return `${year}-${month}-${day}T${timePart}`;
};

