/*
Copyright [2024] [Gerald Rocher]

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import ResilienceMetricsScoreCardCharts from './ResilienceMetricsScoreCardCharts';

const ResilienceMetricsScoreCardsManager = ({
    savedSelections,
    setSavedSelections,    
    reloadSelection
}) => {

    // Function to delete saved metrics for a particular record
    const deleteSavedMetrics = (indexToDelete) => {
        const newSelections = savedSelections.filter((_, index) => index !== indexToDelete);
        setSavedSelections(newSelections);
    };

    // Function to confirm/cancel deletion
    const handleDeleteClick = (indexToDelete) => {
        Swal.fire({
            title: 'Confirm deletion?',
            text: "This record will be deleted forever!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete!',
            cancelButtonText: 'Cancel',
            customClass: {
                popup: 'dark-mode-popup'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                deleteSavedMetrics(indexToDelete);
                showSuccessMessage();
            }
        });
    };

    const showSuccessMessage = () => {
        Swal.fire({
            title: 'Deleted!',
            text: 'The record has been successfully deleted.',
            icon: 'success',
            customClass: {
                popup: 'dark-mode-popup'
            }
        });
    };

    // Group saved selections by chart name
    const groupByChartName = (selections) => {
        return selections.reduce((acc, selection) => {
            const chartName = selection.chartName || 'Unknown Chart';
            if (!acc[chartName]) acc[chartName] = [];
            acc[chartName].push(selection);
            return acc;
        }, {});
    };

    // Render the saved metrics tables
    const ResilienceMetricsTables = () => {
        const groupedSelections = groupByChartName(savedSelections);
        return Object.keys(groupedSelections).map((chartName, i) => (
            <div key={i} className="table-container">
                <h3>{chartName}</h3>
                <table className="table-metrics">
                    <thead>
                        <tr>
                            <th>Date created</th>
                            <th>Chart Name</th>
                            <th>Metrics</th>
                            <th>Result</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Comments</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {groupedSelections[chartName].map((selection, index) => (
                            <tr key={index} onClick={() => reloadSelection(selection)} className="clickable-row">
                                <td>{selection.timestampSaved}</td>
                                <td>{selection.chartName || 'Unknown Chart'}</td>
                                <td>{selection.function}</td>
                                <td className="result-cell">{selection.result.value}{selection.result.unit}</td>
                                <td>{selection.timestamps[0]}</td>
                                <td>{selection.timestamps[1]}</td>
                                <td>
                                    <textarea
                                        className="comment-input"
                                        type="text"
                                        value={selection.comment || ''}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={(e) => {
                                            const newComment = e.target.value;
                                            const updatedSelections = [...savedSelections];
                                            updatedSelections[index] = { ...selection, comment: newComment };
                                            setSavedSelections(updatedSelections);
                                        }}
                                    />
                                </td>
                                <td>
                                    <button
                                        className="table-delete-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteClick(index);
                                        }}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ));
    };

    return (
        <div>
            {savedSelections.length > 0 && (
                <div>
                    <h2>Resilience scorecards</h2>
                    <ResilienceMetricsScoreCardCharts data={savedSelections} />
                    {ResilienceMetricsTables()}
                </div>
            )}
        </div>
    );
};

export default ResilienceMetricsScoreCardsManager;