/*
Copyright [2024] [Gerald Rocher]

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { 
    normalizeDate, 
} from './utils';

export const calculateMetricsValue = (selectedFunction, linePositions, chartData, selectedColumns) => {
    const firstLabel = chartData.labels[linePositions[0]];
    const secondLabel = chartData.labels[linePositions[1]];
    let totalArea, above, under;

    switch (selectedFunction) {
        case 'Recovery_time':
        case 'Down_time':
            return calculateElapsedTime(firstLabel, secondLabel);
        case 'Effectiveness_loss':
            above = calculateAreaAboveCurve(selectedColumns[0], linePositions[0], linePositions[1]);
            under = calculateAreaUnderCurve(selectedColumns[0], linePositions[0], linePositions[1]);
            totalArea = parseFloat(above.value) + parseFloat(under.value);
            return { value: (parseFloat(above.value) / totalArea * 100).toFixed(2), unit: "%" };
        case 'Degradation_rate':
        case 'Recovery_rate':
            return calculateSlope(selectedColumns[0], linePositions[0], linePositions[1]);
        case 'Robustness':
            above = calculateAreaAboveCurve(selectedColumns[0], linePositions[0], linePositions[1]);
            under = calculateAreaUnderCurve(selectedColumns[0], linePositions[0], linePositions[1]);
            totalArea = parseFloat(above.value) + parseFloat(under.value);
            return { value: (parseFloat(under.value) / totalArea * 100).toFixed(2), unit: "%" };
        case 'Automatic':
            return detectPhases(selectedColumns[0], linePositions[0], linePositions[1]);
        default:
            return null;
    }
};

export const calculateElapsedTime = (timestamp_start, timestamp_end) => {
    if (!timestamp_start || !timestamp_end) return 0;

    const startTime = Date.parse(normalizeDate(timestamp_start));
    const endTime = Date.parse(normalizeDate(timestamp_end));

    const diffInSeconds = Math.abs(endTime - startTime) / 1000;

    const days = Math.floor(diffInSeconds / (24 * 3600));
    const hours = Math.floor((diffInSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = Math.floor(diffInSeconds % 60);
    return { value: `${days}j-${hours}h-${minutes}mn-${seconds}s`, unit: "duration" };
};

export const calculateAreaUnderCurve = (column_data, startPointIndex, endPointIndex) => {
    if (!column_data || startPointIndex === null || endPointIndex === null) return 0;

    const values = column_data.smoothedValues.slice(startPointIndex, endPointIndex + 1);
    const timestamps = column_data.timestamps.slice(startPointIndex, endPointIndex + 1);

    let area = 0;
    for (let i = 0; i < values.length - 1; i++) {
        const height = (values[i] + values[i + 1]) / 2;
        const width = (Date.parse(normalizeDate(timestamps[i + 1])) - Date.parse(normalizeDate(timestamps[i]))) / 1000;
        area += height * width;
    }
    return { value: parseFloat(area.toFixed(2)), unit: "None" };
};

export const calculateAreaAboveCurve = (column_data, startPointIndex, endPointIndex) => {
    if (!column_data || startPointIndex === null || endPointIndex === null) return 0;

    const values = column_data.smoothedValues.slice(startPointIndex, endPointIndex + 1);
    const timestamps = column_data.timestamps.slice(startPointIndex, endPointIndex + 1);
    
    let areaAboveCurve = 0;
    
    for (let i = 0; i < values.length - 1; i++) {
        const heightAboveCurve = ((1.0 - values[i]) + (1.0 - values[i + 1])) / 2;
        const width = (Date.parse(normalizeDate(timestamps[i + 1])) - Date.parse(normalizeDate(timestamps[i]))) / 1000;      
        areaAboveCurve += heightAboveCurve * width;
    }
    return { value: areaAboveCurve.toFixed(2), unit: "None" };
};

export const calculateSlope = (column_data, startPointIndex, endPointIndex) => {
    if (!column_data || startPointIndex === null || endPointIndex === null || startPointIndex >= endPointIndex) return { slope: 0, unit: "%/s" };

    const values = column_data.smoothedValues;
    const timestamps = column_data.timestamps;

    if (startPointIndex < 0 || endPointIndex >= values.length) return { slope: 0, unit: "%/s" };

    const y1 = values[startPointIndex];
    const y2 = values[endPointIndex];
    const x1 = Date.parse(normalizeDate(timestamps[startPointIndex]));
    const x2 = Date.parse(normalizeDate(timestamps[endPointIndex]));

    const deltaY = y2 - y1;
    const deltaX = (x2 - x1) / 1000;  // deltaX in seconds

    if (deltaX === 0) return { slope: 0, unit: "%/s" };

    let slope = deltaY / deltaX;  // initial slope expressed in %/s
    let unit = "%/s";  // default unit in %/s

    // Automatic unit adjustment according to deltaX
    if (deltaX > 86400) {  // more than one day
        slope *= 86400;
        unit = "%/day";
    } else if (deltaX >= 3600) {  // more than one hour
        slope *= 3600;
        unit = "%/hour";
    } else if (deltaX >= 60) {  // more than one minute
        slope *= 60;
        unit = "%/min";
    }

    slope *=100;
    return { value: slope.toFixed(2), unit };
};

export const detectPhases = (column_data, startPointIndex, endPointIndex) => {
    if (!column_data || startPointIndex === null || endPointIndex === null) return [];

    return { value: "Not implemented", unit: "N/A" };
};
