/*
Copyright [2024] [Gerald Rocher]

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale, Filler } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';

ChartJS.register(Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale, Filler, zoomPlugin, annotationPlugin, CrosshairPlugin);

function GraphManager({
    chartRef,
    chartData,
    linePositions,
    setLinePositions,
    selectedLineIndex,
    setSelectedLineIndex,
    highlightedLineIndex,
    setHighlightedLineIndex,
    setCircles,
    markers,
    displayedChartsData,
    setSelectedColumns
}) {
    const handleMouseDown = (event) => {
        const chart = chartRef.current;
        if (chart && highlightedLineIndex !== null) {
            setSelectedLineIndex(highlightedLineIndex);
        }
    };

    const handleMouseMove = (event) => {
        const chart = chartRef.current;
        if (!chart) return;

        const x = chart.scales.x.getValueForPixel(event.nativeEvent.offsetX);
        if (x === null) return;

        const circles_ = chartData.datasets.flatMap(dataset => {
            const yValue = dataset.data[x];
            return markers
                .filter(marker => dataset.label === marker.label)
                .map(marker => ({
                    position: marker.position,
                    radius: 1000 - (yValue * 1000),
                    label: marker.label
                }));
        });

        setCircles(circles_);

        if (selectedLineIndex !== null) {
            setLinePositions(prev => {
                const newPositions = [...prev];
                newPositions[selectedLineIndex] = x;
                return newPositions;
            });
        } else {
            const closestIndex = linePositions.findIndex(pos => Math.abs(pos - x) < 10);
            setHighlightedLineIndex(closestIndex !== -1 ? closestIndex : null);
        }
    };

    const handleMouseUp = () => {
        setSelectedLineIndex(null);
        setHighlightedLineIndex(null);
    };

    const handleLegendClick = (event, legendItem) => {
        const selectedData = displayedChartsData.find(data => data.label === legendItem.text);

        setSelectedColumns(prevSelectedColumns => {
            const isSelected = prevSelectedColumns.some(col => col.label === legendItem.text);
            return isSelected 
                ? prevSelectedColumns.filter(col => col.label !== legendItem.text) 
                : [...prevSelectedColumns, selectedData];
        });
    };

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                type: 'category',
                title: {
                    display: true,
                    text: 'Time',
                    font: {
                        size: 20,
                        family: 'Arial',
                        style: 'normal',
                        weight: 'bold',
                        color: 'white',
                    },
                },
                ticks: {
                    font: {
                        size: 14,
                        color: 'white',
                    },
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Effectiveness',
                    font: {
                        size: 20,
                        family: 'Arial',
                        style: 'normal',
                        weight: 'bold',
                        color: 'white',
                    },
                },
                ticks: {
                    font: {
                        size: 14,
                        color: 'white',
                    },
                },
                min: 0.0,
            },
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                titleFont: { size: 16 },
                bodyFont: { size: 14 },
                padding: 10,
                displayColors: true,
            },
            legend: {
                labels: {
                    font: {
                        size: 20,
                        family: 'Arial',
                        style: 'normal',
                        weight: 'bold',
                        color: 'white',
                    },
                    padding: 20,
                },
                onClick: handleLegendClick,
            },
            annotation: {
                annotations: linePositions.map((position, index) => ({
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x',
                    value: position,
                    borderColor: highlightedLineIndex === index ? 'white' : 'grey',
                    borderWidth: 3,
                    borderDash: [5, 5],
                    label: {
                        content: `Ligne ${index + 1}`,
                        enabled: true,
                        position: 'top',
                    },
                })),
            },
            zoom: {
                pan: { enabled: false, mode: 'x' },
                zoom: { wheel: { enabled: true, speed: 0.1, modifierKey: 'ctrl' }, mode: 'x' },
            },
            crosshair: {
                sync: { enabled: false },
                zoom: { enabled: false },
                snap: { enabled: false },
                line: { color: 'rgba(255, 255, 255, 0.8)', width: 1 },
            },
        },
        elements: {
            point: {
                hoverRadius: 15,
            },
        },
    };

    return (
        <div className="graphs">
            {chartData ? (
                <Line
                    ref={chartRef}
                    data={chartData}
                    options={chartOptions}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                />
            ) : (
                <div>No dataset selected.</div>
            )}
        </div>
    );
}

export default GraphManager;
