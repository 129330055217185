/*
Copyright [2024] [Gerald Rocher]

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import Accessibility from 'highcharts/modules/accessibility';

highchartsMore(Highcharts);
solidGauge(Highcharts);
Accessibility(Highcharts);

Highcharts.setOptions({
    lang: { noData: '' },
    credits: { enabled: false },
    accessibility: { enabled: true }
});

const ResilienceMetricsScoreCardCharts = ({ data }) => {
    if (!data || data.length === 0) return <div>Loading...</div>;

    const groupByChartName = (data) => {
        return data.reduce((acc, item) => {
            if (!acc[item.chartName]) acc[item.chartName] = [];
            acc[item.chartName].push(item);
            return acc;
        }, {});
    };

    const createColorMap = (data, colors) => {
        const colorMap = {};
        let colorIndex = 0;   
        data.forEach((item) => {
            if (!colorMap[item.function]) {
                colorMap[item.function] = colors[colorIndex % colors.length];
                colorIndex++;
            }
        });
        return colorMap;
    };

    const chartOptions = useMemo(() => {
        const groupedData = groupByChartName(data);
        console.log(groupedData);
        const colors = ['#316879', '#f47a60', '#7fe7dc', '#ced7d8', '#ffd79d', '#b85042'];
        const colorMap = createColorMap(data, colors);

        return Object.keys(groupedData).map((chartName) => {
            const seriesData = [];
            let categories = [];
            
            groupedData[chartName].forEach((item) => {
                if (!categories.includes(item.function)) {
                    categories.push(item.function);
                }

                const value = Math.abs(parseFloat(item.result.value));
                const seriesIndex = categories.indexOf(item.function);
                
                if (!seriesData[seriesIndex]) {
                    seriesData[seriesIndex] = {
                        name: item.function,
                        data: Array(categories.length).fill(0),
                        color: colorMap[item.function],
                        stack: chartName
                    };
                }   
                seriesData[seriesIndex].data[categories.indexOf(item.function)] = value;
            });

            return {
                chart: {
                    type: 'column',
                    inverted: true,
                    polar: true,
                    backgroundColor: 'var(--metrics-bg-color)',
                    borderRadius: '10px',
                },
                title: {
                    text: chartName,
                    align: 'left',
                    style: { color: 'var(--primary-text-color)' }
                },
                tooltip: {
                    outside: true
                },
                pane: {
                    size: '85%',
                    innerSize: '20%',
                    endAngle: 270
                },
                xAxis: {
                    categories,
                    tickInterval: 1,
                    labels: {
                        align: 'right',
                        useHTML: true,
                        allowOverlap: true,
                        style: { fontSize: '16px', color: 'var(--primary-text-color)' }
                    },
                    lineWidth: 0,
                    gridLineWidth: 0,
                    tickColor: 'var(--primary-text-color)'
                },
                yAxis: {
                    lineWidth: 0,
                    tickInterval: 25,
                    reversedStacks: false,
                    endOnTick: true,
                    showLastLabel: true,
                    gridLineWidth: 0,
                    tickColor: 'var(--primary-text-color)'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        borderWidth: 0,
                        pointPadding: 0,
                        groupPadding: 0.15,
                        borderRadius: '50%'
                    }
                },
                series: seriesData
            };
        });
    }, [data]);

    return chartOptions.length > 0 ? (
        <div style={{ display: 'flex', gap: '20px' }}>
            {chartOptions.map((options, index) => (
                <div
                    key={index}
                    style={{
                        width: `calc(${100 / chartOptions.length}% - 10px)`,
                        height: '500px'
                    }}
                >
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        containerProps={{ style: { width: '100%', height: '100%' } }}
                    />
                </div>
            ))}
        </div>
    ) : (
        <div>Loading...</div>
    );
};

export default ResilienceMetricsScoreCardCharts;
