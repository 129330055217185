/*
Copyright [2024] [Gerald Rocher]

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { smoothData } from '../utils/utils';
import Swal from 'sweetalert2';

const getValidDate = (date, defaultDate) => {
  return date === '' ? defaultDate : date;
};

export const fetchColumnData = async (column, dateStart, dateEnd, smoothingFactor) => {
    try {
      
      const validStartDate = getValidDate(dateStart, '1970-01-01T00:00:00');
      const validEndDate = getValidDate(dateEnd, '2100-01-01T00:00:00');
      const response = await axios.get(`http://37.27.127.166:30887/get-range/${column}?start=${validStartDate}&end=${validEndDate}`);
      // const response = await axios.get(`http://aware4bc-resilience.dynabic.dev/get-range/${column}?start=${validStartDate}&end=${validEndDate}`);
      const timestamps = response.data.map(item => new Date(item.timestamp).toLocaleString());
      const values = response.data.map(item => parseFloat(item.value));
      const ctx = response.data[0]?.context;
      const smoothedValues = smoothData(values, smoothingFactor);
  
      return {
        timestamps,
        smoothedValues,
        label: column,
        ctx,
        dateFrom: timestamps[0],
        dateTo: timestamps[timestamps.length - 1],
        recordCount: response.data.length
      };
    } catch (error) {
      console.error("Error fetching column data:", error);
      throw error;
    }
};

const TimeSeries = ({ visibleColumns, toggleDataset }) => {
    const [columns, setColumns] = useState([]);
    const fileInputRef = useRef(null);

  const fetchColumns = async () => {
    try {
      // const { data: fetchedColumns } = await axios.get('http://aware4bc-resilience.dynabic.dev/database-info');
      const { data: fetchedColumns } = await axios.get('http://37.27.127.166:30887/database-info');
      setColumns(prevColumns => {
        const hasColumnsChanged = (oldColumns, newColumns) => {
          if (oldColumns.length !== newColumns.length) return true;

          return newColumns.some(newColumn => {
            const oldColumn = oldColumns.find(col => col.column_name === newColumn.column_name);
            return !oldColumn || 
                   oldColumn.context !== newColumn.context ||
                   oldColumn.newest_timestamp !== newColumn.newest_timestamp ||
                   oldColumn.oldest_timestamp !== newColumn.oldest_timestamp;
          });
        };

        if (hasColumnsChanged(prevColumns, fetchedColumns)) {
          return fetchedColumns;
        }
        return prevColumns;
      });
    } catch (error) {
      console.error('There was an error fetching the columns!', error);
    }
  };

  const handleExportCSV = async () => {
    try {
      // const response = await axios.get('http://aware4bc-resilience.dynabic.dev/export-csv', {
      const response = await axios.get('http://37.27.127.166:30887/export-csv', {  
      responseType: 'blob',
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'database_dump.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting CSV:", error);
    }
  };

const handleImportCSV = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      Swal.fire({
        icon: 'warning',
        title: 'No file selected',
        text: 'Please select a CSV file before proceeding.',
        customClass: {
          popup: 'dark-mode-popup'
        }
      });
      return;
    }
  
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      // const response = await axios.post('http://aware4bc-resilience.dynabic.dev/import-csv', formData, {
      const response = await axios.post('http://37.27.127.166:30887/import-csv', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
  
      if (response.data && response.data.stats) {
        const stats = response.data.stats;
        const statsMessage = Object.entries(stats)
          .map(([column, count]) => `<li><strong>${column}:</strong> ${count} records added</li>`)
          .join('');
  
        Swal.fire({
          icon: 'success',
          title: 'Data Imported Successfully!',
          html: `<ul style="text-align: left;">${statsMessage}</ul>`,
          confirmButtonText: 'OK',
          customClass: {
            popup: 'dark-mode-popup'
          }          
        });
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Import Successful',
          text: 'Data imported successfully, but no statistics were returned.',
          customClass: {
            popup: 'dark-mode-popup'
          }
        });
      }
    } catch (error) {
      console.error("Error importing CSV:", error);
      Swal.fire({
        icon: 'error',
        title: 'Import Failed',
        text: error.response?.data?.error || 'An error occurred while importing the CSV.',
        customClass: {
          popup: 'dark-mode-popup'
        }
      });
    }
  };

  useEffect(() => {
    fetchColumns();
    const intervalId = setInterval(fetchColumns, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <h2>Available timeseries</h2>
      {!columns.length ? (
        <div>Connecting to the database...</div>
      ) : (
        <div className="effectiveness-curves-selection">
          <table className="table-theme1">
            <thead>
              <tr>
                <th>Concern</th>
                <th>Date_from</th>
                <th>Date_to</th>
                <th>Records</th>
                <th>Context</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {columns.map((column, index) => {
                const { column_name, oldest_timestamp, newest_timestamp, total_count, context } = column;
                const isVisible = visibleColumns.includes(column_name);
                return (
                  <tr key={index}>
                    <td>{column_name}</td>
                    <td>{oldest_timestamp || 'N/A'}</td>
                    <td>{newest_timestamp || 'N/A'}</td>
                    <td>{total_count || 0}</td>
                    <td>{context || 'N/A'}</td>
                    <td>
                      <button
                        className={`table-fetch-button ${isVisible ? 'btn-hide' : 'btn-show'}`}
                        onClick={() => toggleDataset(column_name)}>
                        {isVisible ? 'Hide' : 'Show'}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <div>
        {columns.length > 0 && (
          <button onClick={handleExportCSV} className="button">
            Export Data to CSV
          </button>
        )}

        <button onClick={() => fileInputRef.current.click()} className="button">
          Import Data from CSV
        </button>
        <input 
          type="file" 
          accept=".csv" 
          onChange={handleImportCSV} 
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
};

export default TimeSeries;
