/*
Copyright [2024] [Gerald Rocher]

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import Swal from 'sweetalert2';
import { calculateMetricsValue } from '../utils/resilience-metrics-functions';

function ResilienceMetricsSelector({
    linePositions,
    selectedColumns,
    chartData,
    savedSelections,
    setSavedSelections,
    dateStart,
    dateEnd,
}) {
    const handleFunctionSelection = (event) => {
        if (!chartData || linePositions.length !== 2 || selectedColumns.length !== 1) {
            Swal.fire('Error', 'Please ensure all conditions are met to select a metric.', 'error');
            return;
        }

        const selectedFunction = event.target.value;
        linePositions.sort((a, b) => a - b);

        const metricsValue = calculateMetricsValue(selectedFunction, linePositions, chartData, selectedColumns);
        const chartName = selectedColumns[0].label || 'Unknown Chart';
        const timestamps = [chartData.labels[linePositions[0]], chartData.labels[linePositions[1]]];

        const existingIndex = savedSelections.findIndex(selection =>
            selection.chartName === chartName && selection.function === selectedFunction
        );

        const newSelection = {
            timestampSaved: new Date().toLocaleString(),
            chartName,
            function: selectedFunction,
            result: metricsValue,
            timestamps,
            date_range: [{ from: dateStart, to: dateEnd }],
            points: [
                { timestamp: timestamps[0], value: selectedColumns[0].smoothedValues[linePositions[0]], index: linePositions[0] },
                { timestamp: timestamps[1], value: selectedColumns[0].smoothedValues[linePositions[1]], index: linePositions[1] },
            ],
        };

        setSavedSelections((prevSelections) => {
            if (existingIndex !== -1) {
                const updatedSelections = [...prevSelections];
                updatedSelections[existingIndex] = { ...updatedSelections[existingIndex], ...newSelection };
                return updatedSelections;
            }
            return [...prevSelections, newSelection];
        });
    };

    return (
        <div className="resilience-metrics-selector">
            <select
                onChange={handleFunctionSelection}
                disabled={selectedColumns && selectedColumns.length !== 1}
            >
                <option value="" disabled selected>
                    Please select a metrics...
                </option>
                <option value="Degradation_rate">Degradation rate</option>
                <option value="Robustness">Robustness</option>
                <option value="Down_time">Down time</option>
                <option value="Recovery_time">Recovery time</option>
                <option value="Recovery_rate">Recovery rate</option>
                <option value="Effectiveness_loss">Effectiveness loss</option>
            </select>
            {selectedColumns && selectedColumns.length !== 1 && (
                <p style={{ color: 'gray' }}>Please select one curve by clicking on the legend.</p>
            )}
        </div>
    );
}

export default ResilienceMetricsSelector;